import React from "react";
import SEO from "components/seo";
import {
  ContactForm,
  ContentPhoto,
  FullPhoto,
  Hero,
  Layout,
  Navigation,
  TextBox,
  WhatWeDid,
} from "components/CaseLayout";
import { BEEFURY } from "shared/Types/cases";
import { useCaseData } from "shared/Hooks/CasesNew/useCaseData";

const Beefury = () => {
  const data = useCaseData(BEEFURY);

  return (
    <Layout>
      <SEO title={data.title} description={data.seo}/>
      <Hero color="#000" data={data.hero}/>
      <Navigation type={BEEFURY}/>
      <WhatWeDid data={data.what_we_did}/>
      <FullPhoto data={data.full_images.first}/>
      <TextBox data={data.text_boxes.first}/>
      <ContentPhoto data={data.content_images.first}/>
      <TextBox background="#0A0A0A" color="#fff" data={data.text_boxes.second}/>
      <FullPhoto data={data.full_images.second}/>
      <ContentPhoto data={data.content_images.second}/>
      <ContentPhoto data={data.content_images.third}/>
      <ContentPhoto data={data.content_images.fourth}/>
      <ContentPhoto data={data.content_images.fifth}/>
      <ContentPhoto data={data.content_images.sixth}/>
      <ContentPhoto data={data.content_images.seventh}/>
      <ContactForm/>
    </Layout>
  );
};

export default Beefury;
